import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import imgC from "../../assets/image/l3/jpg/l3-content-img.jpg";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section pt-12 pb-10 bg-default-6 py-xl-25 ">
        <Container>
          <Row className="align-items-center justify-content-md-center">
            <Col lg="4" md="10">
              <div className="content-text text-center text-lg-left mb-9 mb-lg-0 pr-xl-11">
                <h2 className="title gr-text-5 mb-8">
                  Experienced professionals who have been there.
                </h2>
                <p className="gr-text-8 mb-9">
                  Our consultants have years of experience in sales and know exactly how the sales process should work from start to end. From day 1 we work as a team with full commitment to deliver tangible results.
                </p>
                <Button className="gr-hover-y" href="./contact/">Get Started</Button>
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="content-img rounded-8">
                <img className="w-100 rounded-8" src={imgC} alt="" />
              </div>
            </Col>
            <Col lg="4" md="6">
              <div className="stats-wrapper mt-6 mt-md-0 pl-xl-9">
                <div
                  className="single-stat py-6"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  <h3 className="title gr-text-5 mb-5">1M ARR</h3>

                  <p className="gr-text-8 mb-0">
                    We specialise in helping companies reach the 1 million mark faster.
                  </p>
                </div>
                <div
                  className="single-stat py-6"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <h3 className="title gr-text-5 mb-5">
                    Independent
                  </h3>
                  <p className="gr-text-8 mb-0">
                    We generate revenue while you focus on building an amazing product.
                  </p>
                </div>
                <div
                  className="single-stat py-6"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                >
                  <h3 className="title gr-text-5 mb-5">
                    Personal
                  </h3>
                  <p className="gr-text-8 mb-0">
                    Our in-depth process gives invaluable insights that will help scale your revenue.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
