import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "react-bootstrap";

import imgS1 from "../../assets/image/l3/jpg/l3-service-img1.jpg";
import imgS2 from "../../assets/image/l3/jpg/l3-service-img2.jpg";
import imgS3 from "../../assets/image/l3/jpg/l3-service-img3.jpg";

const Services = () => {
  return (
    <>
      {/* <!-- Services Area --> */}
      <div className="service-section bg-default-2 pt-12 py-lg-23">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xl="6" lg="8" md="10">
              <div className="section-title text-center mb-11 mb-lg-15">
                <h2 className="title gr-text-4 mb-6">
                  Services we offer: 
                </h2>
                <p className="gr-text-8 mb-0 px-lg-7 px-xl-0">
                  Here are the tools we can apply to help scale your revenue.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col lg="4" md="6" sm="10" className="mb-13 mb-lg-0">
              <div className="service-card">
                <h3 className="card-title gr-text-7 mb-7">Mystery Shopping</h3>
                <div className="img-thumb mb-8">
                  <img className="rounded-10 w-100" src={imgS1} alt="" />
                </div>
                <p className="excerpt gr-text-9 mb-6">
                  Our team will go through your sales process incognito and make a full evaluation of how your sales team handled it. Based on the outcome we will give you actionable recommendations.  
                <br></br>
                <br></br>
                <br></br>

                </p>
                <Link
                  to="/contact/"
                  className="btn-link with-icon gr-text-9 font-weight-bold"
                >
                  I Need This{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="6" sm="10" className="mb-13 mb-lg-0">
              <div className="service-card">
                <h3 className="card-title gr-text-7 mb-7">Customer Communication</h3>
                <div className="img-thumb mb-8">
                  <img className="rounded-10 w-100" src={imgS2} alt="" />
                </div>
                <p className="excerpt gr-text-9 mb-6">
                  Thousands of emails, messages and phone calls exchanged with our customers helped us to understand what messasge brings the best results. Our spiecialists will analyze your current communication style and make suggestions on how to optimize and get the best results. 
                </p>
                <Link
                  to="/contact/"
                  className="btn-link with-icon gr-text-9 font-weight-bold"
                >
                  I Need This{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
            <Col lg="4" md="6" sm="10" className="mb-13 mb-lg-0">
              <div className="service-card">
                <h3 className="card-title gr-text-7 mb-7">Process Review & Structuring</h3>
                <div className="img-thumb mb-8">
                  <img className="rounded-10 w-100" src={imgS3} alt="" />
                </div>
                <p className="excerpt gr-text-9 mb-6">
                  If your sales process isn’t working as expected we can help. We are able to completely dissect it and help rebuild it. This will result with a streamlined and efficient process that will increase sales. 
                  <br></br>
                  <br></br>
                  <br></br>
                </p>
                <Link
                  to="/contact/"
                  className="btn-link with-icon gr-text-9 font-weight-bold"
                >
                  I Need This{" "}
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Services;
