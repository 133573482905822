import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import imgH from "../../assets/image/btg-landing/png/portrait-hero.png";

const Hero = () => {

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="position-relative bg-pattern pattern-2 pt-24 pt-md-15 pt-lg-13 pt-xl-25">
        <Container>
          <Row className="justify-content-sm-end align-items-center">
            <Col
              lg="6"
              md="8"
              className="py-md-16 py-lg-21"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <div className="hero-content pr-xl-19 mb-9 mb-md-0">
                <h1 className="title gr-text-3 mb-6">
                  Boost your sales while keeping focus on your core product
                </h1>
                <p className="gr-text-8 mb-8">
                  Let our team go through your sales process and identify areas for improvement. Grow your business by removing friction with actionable results delivered rapidly.
                </p>
              </div>
            </Col>
            <Col lg="5" md="4" sm="7" className="offset-lg-1 align-self-sm-end">
              <div
                className="hero-img"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
              >
                <img className="w-100" src={imgH} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
